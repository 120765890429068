// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../assets/plutchiks-wheel.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".header {\n    width: 100%;\n    height:50px;\n    line-height:50px;\n    font-size: 1.5em;\n    font-weight:500;\n    text-align: left;\n    color: #999;  \n    border-bottom: 1px solid white;\n    background-color:#ece9e3;\n}\n    .header__website-name {\n        display: inline-block;\n        padding-left:20px;\n    }\n    .header__logo {\n        display: inline-block;\n        height: 16px;\n        width: 50px;\n        background: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat 8px 0px;\n        background-size: contain;\n        border-radius: 30px;\n    }", "",{"version":3,"sources":["webpack://./src/css/Header.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,WAAW;IACX,gBAAgB;IAChB,gBAAgB;IAChB,eAAe;IACf,gBAAgB;IAChB,WAAW;IACX,8BAA8B;IAC9B,wBAAwB;AAC5B;IACI;QACI,qBAAqB;QACrB,iBAAiB;IACrB;IACA;QACI,qBAAqB;QACrB,YAAY;QACZ,WAAW;QACX,qEAAkE;QAClE,wBAAwB;QACxB,mBAAmB;IACvB","sourcesContent":[".header {\n    width: 100%;\n    height:50px;\n    line-height:50px;\n    font-size: 1.5em;\n    font-weight:500;\n    text-align: left;\n    color: #999;  \n    border-bottom: 1px solid white;\n    background-color:#ece9e3;\n}\n    .header__website-name {\n        display: inline-block;\n        padding-left:20px;\n    }\n    .header__logo {\n        display: inline-block;\n        height: 16px;\n        width: 50px;\n        background: url('../assets/plutchiks-wheel.png') no-repeat 8px 0px;\n        background-size: contain;\n        border-radius: 30px;\n    }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
