import '../css/Frequencies.css'


export const Frequencies = () => {

    return (

        <header className="frequencies">

        </header>
  
    )
}