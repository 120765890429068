// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../assets/plutchiks-wheel.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".output-circle {\n    margin: 0 auto;\n    position: relative;\n    border:0;\n    /*   match h/w with the \"containerScaler\" variable in \"Circle.tsx\" of 0.44  */\n    height: 435px; \n    width: 436px;\n    overflow: hidden;\n    background: white;\n    padding: 0px;\n    border-radius: 220px;\n    z-index: 1;\n}\n    .output-circle__plutchiks-wheel {\n        margin: 0 auto;\n        border:0;\n        margin-top:-3px;\n        margin-left:-1px;\n        height: 440px;\n        width: 440px;\n        background: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n        background-size: contain;\n        z-index:10;\n    }", "",{"version":3,"sources":["webpack://./src/css/OutputCircle.css"],"names":[],"mappings":"AAAA;IACI,cAAc;IACd,kBAAkB;IAClB,QAAQ;IACR,6EAA6E;IAC7E,aAAa;IACb,YAAY;IACZ,gBAAgB;IAChB,iBAAiB;IACjB,YAAY;IACZ,oBAAoB;IACpB,UAAU;AACd;IACI;QACI,cAAc;QACd,QAAQ;QACR,eAAe;QACf,gBAAgB;QAChB,aAAa;QACb,YAAY;QACZ,mDAAgD;QAChD,wBAAwB;QACxB,UAAU;IACd","sourcesContent":[".output-circle {\n    margin: 0 auto;\n    position: relative;\n    border:0;\n    /*   match h/w with the \"containerScaler\" variable in \"Circle.tsx\" of 0.44  */\n    height: 435px; \n    width: 436px;\n    overflow: hidden;\n    background: white;\n    padding: 0px;\n    border-radius: 220px;\n    z-index: 1;\n}\n    .output-circle__plutchiks-wheel {\n        margin: 0 auto;\n        border:0;\n        margin-top:-3px;\n        margin-left:-1px;\n        height: 440px;\n        width: 440px;\n        background: url('../assets/plutchiks-wheel.png');\n        background-size: contain;\n        z-index:10;\n    }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
