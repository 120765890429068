let words = new Map<string, number[]>();

words.set('serenity', [502, 243, 194, 187])
words.set('serene', [502, 243, 194, 187])
words.set('joy', [501, 218, 85, 19])
words.set('joyful', [501, 218, 85, 19])
words.set('ecstasy', [502, 179, 44, 5])
words.set('ecstatic', [502, 179, 44, 5])
words.set('acceptance', [589, 386, 167, 62])
words.set('accept', [589, 386, 167, 62])
words.set('accepting', [589, 386, 167, 62])
words.set('truth', [666, 298, 103, 22])
words.set('truthful', [666, 298, 103, 22])
words.set('trust', [666, 298, 103, 22])
words.set('trusting', [666, 298, 103, 22])
words.set('trusted', [666, 298, 103, 22])
words.set('admire', [698, 312, 139, 15])
words.set('admiration', [698, 312, 139, 15])
words.set('admiring', [698, 312, 139, 15])
words.set('admired', [698, 312, 139, 15])
words.set('terror', [817, 507, 14, 185])
words.set('terrified', [817, 507, 14, 185])
words.set('terrorism', [817, 507, 14, 185])
words.set('fear', [754, 479, 144, 150])
words.set('feared', [754, 479, 144, 150])
words.set('fearing', [754, 479, 144, 150])
words.set('apprehension', [417, 407, 130, 90])
words.set('apprehensive', [417, 407, 130, 90])
words.set('apprehensively', [417, 407, 130, 90])
words.set('amazed', [723, 666, 105, 10])
words.set('amazement', [723, 666, 105, 10])
words.set('amazing', [723, 666, 105, 10])
words.set('surprise', [657, 660, 83, 28])
words.set('surprising', [657, 660, 83, 28])
words.set('surprised', [657, 660, 83, 28])
words.set('distraction', [591, 565, 97, 125])
words.set('distracted', [591, 565, 97, 125])
words.set('distracting', [591, 565, 97, 125])
words.set('grief', [479, 792, 171, 181])
words.set('grieving', [479, 792, 171, 181])
words.set('grieved', [479, 792, 171, 181])
words.set('sad', [477, 751, 192, 158])
words.set('sadness', [477, 751, 192, 158])
words.set('sadly', [477, 751, 192, 158])
words.set('pensiveness', [492, 539, 165, 118])
words.set('pensive', [492, 539, 165, 118])
words.set('pensively', [492, 539, 165, 118])
words.set('loath', [231, 614, 39, 171])
words.set('loathing', [231, 614, 39, 171])
words.set('loathimgly', [231, 614, 39, 171])
words.set('loathed', [231, 614, 39, 171])
words.set('disgust', [305, 687, 105, 175])
words.set('distgusting', [305, 687, 105, 175])
words.set('disgusted', [305, 687, 105, 175])
words.set('bored', [420, 605, 198, 132])
words.set('boring', [420, 605, 198, 132])
words.set('boredom', [420, 605, 198, 132])
words.set('rage', [177, 516, 12, 190])
words.set('anger', [246, 493, 25, 173])
words.set('angry', [246, 493, 25, 173])
words.set('angered', [246, 493, 25, 173])
words.set('angrily', [246, 493, 25, 173])
words.set('annoyance', [334, 486, 52, 148])
words.set('annoyingly', [334, 486, 52, 148])
words.set('annoy', [334, 486, 52, 148])
words.set('annoying', [334, 486, 52, 148])
words.set('interest', [385, 362, 133, 73])
words.set('interested', [385, 362, 133, 73])
words.set('interesting', [385, 362, 133, 73])
words.set('interestingly', [385, 362, 133, 73])
words.set('anticipation', [421, 385, 161, 86])
words.set('anticipated', [421, 385, 161, 86])
words.set('anticipating', [421, 385, 161, 86])
words.set('vigilance', [300, 310, 106, 78])
words.set('vigilantly', [300, 310, 106, 78])
words.set('shit', [225, 525, 47, 172])
words.set('shitting', [210, 515, 47, 172])
words.set('fuck', [163, 502, 47, 172])
words.set('fucking', [193, 580, 47, 172])
words.set('fucked', [190, 590, 47, 172])
words.set('bollocks', [201, 595, 47, 172])
words.set('arsehole', [199, 589, 47, 172])
words.set('cunt', [195, 593, 47, 172])
words.set('bastard', [190, 603, 47, 172])
words.set('twat', [197, 596, 47, 172])
words.set('amiable', [607, 366, 182, 54])
words.set('amiably', [607, 366, 182, 54])
words.set('amicably', [607, 366, 182, 54])
words.set('adore', [636, 224, 103, 20])
words.set('adored', [636, 224, 103, 20])
words.set('adorable', [636, 224, 103, 20])
words.set('love', [562, 275, 69, 15])
words.set('lovingly', [498, 275, 69, 15])
words.set('loved', [508, 275, 69, 15])
words.set('loving', [518, 275, 69, 15])
words.set('understanding', [507, 322, 188, 44])
words.set('understand', [517, 302, 188, 44])
words.set('comfort', [494, 271, 190, 50])
words.set('comforting', [494, 271, 190, 50])
words.set('comforted', [494, 271, 190, 50])
//words.set('help', [572, 328, 54, 61])
words.set('helping', [572, 328, 54, 61])
words.set('helped', [572, 328, 54, 61])
words.set('assist', [572, 331, 85, 57])
words.set('assting', [572, 331, 85, 57])
words.set('assited', [572, 331, 85, 57])
words.set('amicable', [603, 410, 157, 82])
words.set('amicably', [603, 410, 157, 82])
words.set('amiable', [647, 349, 134, 60])
words.set('amiably', [647, 349, 134, 60])
words.set('adore', [656, 231, 103, 10])
words.set('adoring', [656, 231, 103, 10])
words.set('adorably', [656, 231, 103, 10])
words.set('adored', [656, 231, 103, 10])
words.set('fascinate', [352, 236, 82, 13])
words.set('fascinating', [352, 236, 82, 13])
words.set('fascinated', [352, 236, 82, 13])
words.set('focus', [327, 332, 96, 70])
words.set('focused', [327, 332, 96, 70])
words.set('focussing', [327, 332, 96, 70])
words.set('friend', [587, 328, 117, 69])
words.set('friendly', [587, 328, 117, 69])
words.set('befriend', [587, 328, 117, 69])
words.set('relax', [480, 296, 196, 59])
words.set('relaxing', [480, 296, 196, 59])
words.set('relaxed', [480, 296, 196, 59])
words.set('scared', [756, 476, 44, 165])
words.set('scare', [756, 476, 44, 165])
words.set('scaring', [756, 476, 44, 165])
words.set('fright', [754, 569, 21, 159])
words.set('frightened', [754, 569, 21, 159])
words.set('frightening', [754, 569, 21, 159])
words.set('horror', [826, 523, 10, 189])
words.set('horrifying', [826, 523, 10, 189])
words.set('horrible', [296, 704, 70, 163])
words.set('hate', [232, 514, 30, 156])
words.set('hated', [232, 514, 30, 156])
words.set('hating', [232, 514, 30, 156])
words.set('repulse', [301, 674, 57, 161])
words.set('repulsed', [301, 674, 57, 161])
words.set('repulsive', [301, 674, 57, 161])
//words.set('like', [591, 309, 123, 152])
words.set('liking', [591, 309, 123, 152])
words.set('liked', [591, 309, 123, 152])
words.set('liking', [591, 309, 123, 152])
words.set('likeable', [591, 309, 123, 152])
words.set('polite', [613, 394, 95, 80])
words.set('politely', [613, 394, 95, 80])
words.set('assist', [611, 327, 91, 68])
words.set('assisting', [611, 327, 91, 68])
words.set('assisted', [611, 327, 91, 68])
words.set('respect', [667, 312, 126, 64])
words.set('respecting', [667, 312, 126, 64])
words.set('respected', [667, 312, 126, 64])
words.set('shock', [749, 677, 12, 169])
words.set('shocking', [749, 677, 12, 169])
words.set('shocked', [749, 677, 12, 169])
words.set('awe', [729, 309, 58, 39])
words.set('awesome', [618, 295, 53, 63])
words.set('revenge', [225, 530, 55, 175])
words.set('avenged', [225, 530, 55, 175])
words.set('revenging', [225, 530, 55, 175])
words.set('revengeful', [225, 530, 55, 175])
words.set('armed', [254, 443, 73, 145])
words.set('arming', [254, 443, 73, 145])
words.set('shot', [246, 518, 13, 169])
words.set('shoot', [246, 518, 13, 169])
words.set('shooting', [246, 518, 13, 169])
words.set('smash', [230, 478, 16, 173])
words.set('smashed', [230, 478, 16, 173])
words.set('fury', [183, 500, 15, 182])
words.set('furious', [183, 500, 15, 182])
words.set('fuming', [183, 500, 15, 182])
words.set('agitate', [243, 512, 25, 164])
words.set('agitating', [243, 512, 25, 164])
words.set('agitated', [243, 512, 25, 164])
words.set('wait', [375, 381, 177, 116])
words.set('waiting', [419, 368, 177, 116])
words.set('awaited', [419, 368, 177, 116])
words.set('pensive', [359, 345, 154, 113])
words.set('pensively', [359, 345, 154, 113])
words.set('brilliant', [485, 232, 80, 46])
words.set('brilliantly', [585, 232, 80, 46])
words.set('passive', [455, 388, 193, 99])
words.set('passively', [455, 388, 193, 99])
words.set('sorrow', [496, 791, 185, 175])
words.set('sorrowfully', [496, 791, 185, 175])
words.set('sick', [499, 695, 84, 149])
words.set('sickly', [473, 649, 84, 149])
words.set('bliss', [497, 182, 179, 6])
words.set('blissfully', [497, 182, 179, 6])
words.set('grateful', [640, 356, 107, 43])
words.set('gratitude', [640, 356, 107, 43])
words.set('gratefully', [640, 356, 107, 43])
words.set('laugh', [480, 243, 43, 44])
words.set('laughing', [480, 243, 43, 44])
words.set('laughed', [480, 243, 43, 44])
words.set('hurt', [505, 664, 134, 147])
words.set('hurting', [505, 664, 134, 147])
words.set('hurtfully', [505, 664, 134, 147])
words.set('injured', [507, 627, 156, 154])
words.set('injury', [507, 627, 156, 154])
words.set('resent', [352, 625, 103, 146])
words.set('resentful', [352, 625, 103, 146])
words.set('resentfully', [352, 625, 103, 146])
words.set('resenting', [352, 625, 103, 146])
words.set('jealous', [373, 683, 88, 157])
words.set('jealously', [373, 683, 88, 157])
words.set('contempt', [336, 684, 89, 155])
words.set('contenptful', [336, 684, 89, 155])
words.set('revolted', [257, 707, 37, 184])
words.set('revoltingly', [257, 707, 37, 184])
words.set('agony', [479, 820, 9, 195])
words.set('depressed', [483, 802, 198, 182])
words.set('depressing', [483, 802, 198, 182])
words.set('displeased', [393, 609, 104, 130])
words.set('displeasingly', [393, 609, 104, 130])
words.set('displease', [393, 609, 104, 130])
words.set('regret', [478, 669, 179, 148])
words.set('regretted', [478, 669, 179, 148])
words.set('regretful', [478, 669, 179, 148])
words.set('regretfully', [478, 669, 179, 148])
words.set('guilt', [665, 478, 136, 159])
words.set('guilty', [665, 478, 136, 159])
words.set('isolated', [496, 758, 103, 139])
words.set('isolate', [496, 758, 103, 139])
words.set('isolating', [496, 758, 103, 139])
words.set('lonely', [468, 752, 182, 164])
words.set('alone', [468, 752, 182, 164])
words.set('dismay', [507, 730, 142, 150])
words.set('dismayed', [507, 730, 142, 150])
words.set('disillusioned', [525, 692, 124, 152])
words.set('perplexed', [368, 371, 128, 132])
words.set('perplexing', [368, 371, 128, 132])
words.set('astonished', [696, 686, 55, 146])
words.set('astonishing', [696, 686, 55, 146])
words.set('stimulate', [369, 292, 54, 53])
words.set('stimulating', [369, 292, 54, 53])
//words.set('please', [605, 349, 103, 69])
words.set('pleased', [605, 349, 103, 69])
words.set('pleasing', [605, 349, 103, 69])
words.set('amuse', [543, 260, 68, 66])
words.set('amused', [543, 260, 68, 66])
words.set('amusing', [543, 260, 68, 66])
words.set('amusingly', [543, 260, 68, 66])
words.set('delight', [492, 219, 68, 55])
words.set('delighting', [492, 219, 68, 55])
words.set('delighted', [492, 219, 68, 55])
words.set('triumph', [726, 706, 29, 27])
words.set('triumphantly', [726, 706, 29, 27])
words.set('eager', [319, 320, 42, 50])
words.set('hope', [361, 301, 0, 50])
words.set('hopefully', [361, 301, 0, 50])
words.set('hoping', [361, 301, 0, 50])
words.set('hoped', [361, 301, 0, 50])
words.set('excite', [725, 694, 20, 26])
words.set('exciting', [725, 694, 20, 26])
words.set('excitedly', [725, 694, 20, 26])
words.set('euphoric', [413, 212, 161, 28])
words.set('euphoria', [413, 212, 161, 28])
words.set('enchant', [688, 632, 79, 48])
words.set('enchanting', [688, 632, 79, 48])
words.set('enchanted', [688, 632, 79, 48])
words.set('rapture', [534, 192, 29, 13])
words.set('romance', [598, 233, 27, 28])
words.set('romantic', [598, 233, 27, 28])
words.set('romantically', [598, 233, 27, 28])
words.set('fond', [631, 328, 63, 34])
words.set('fondly', [631, 328, 63, 34])
words.set('sentimentally', [520, 669, 158, -1])
words.set('sentiment', [485, 373, 120, 83])
words.set('attract', [417, 351, 59, 65])
words.set('attractive', [417, 351, 59, 65])
words.set('attractively', [417, 351, 59, 65])
words.set('sexy', [367, 247, 18, 66])
words.set('sexual', [367, 247, 18, 66])
words.set('sex', [367, 247, 18, 66])
words.set('passion', [442, 240, 55, 41])
words.set('passionately', [442, 240, 55, 41])
words.set('infatuated', [721, 296, 28, 20])
words.set('infatuating', [721, 296, 28, 20])
words.set('care', [555, 267, 80, 44])
words.set('caring', [565, 267, 80, 44])
words.set('cared', [545, 267, 80, 44])
words.set('compassion', [551, 289, 104, 52])
words.set('compassionately', [541, 289, 104, 52])
words.set('panic', [804, 487, 16, 164])
words.set('panicing', [804, 487, 16, 164])
words.set('paniced', [804, 487, 16, 164])
words.set('inferior', [479, 620, 113, 131])
words.set('inadequate', [479, 620, 113, 131])
words.set('inadequately', [479, 620, 113, 131])
words.set('wise', [687, 316, 133, 64])
words.set('wisdom', [687, 316, 133, 64])
words.set('wisely', [687, 316, 133, 64])
words.set('worry', [751, 472, 67, 152])
words.set('worried', [751, 472, 67, 152])
words.set('worrying', [751, 472, 67, 152])
words.set('anxious', [776, 511, 60, 155])
words.set('anxiously', [776, 511, 60, 155])
words.set('mortify', [610, 745, 124, 159])
words.set('mortified', [610, 745, 124, 159])
words.set('mortifying', [610, 745, 124, 159])
words.set('dread', [793, 486, -1, 168])
words.set('dreaded', [793, 486, -1, 168])
words.set('dreadful', [301, 642, 90, 166])
words.set('dreadfully', [301, 642, 90, 166])
words.set('dread', [355, 711, 89, 161])
words.set('hostile', [282, 525, 74, 160])
words.set('envy', [388, 674, 128, 147])
words.set('envious', [388, 674, 128, 147])
words.set('enviously', [388, 674, 128, 147])
words.set('suffer', [479, 725, 159, 145])
words.set('suffered', [479, 725, 159, 145])
words.set('suffering', [479, 725, 159, 145])
words.set('dissapoint', [423, 602, 160, 129])
words.set('dissapointing', [423, 602, 160, 129])
words.set('dissapointed', [423, 602, 160, 129])
words.set('shame', [430, 643, 174, 149])
words.set('shameful', [430, 643, 174, 149])
words.set('shamed', [430, 643, 174, 149])
words.set('shamefully', [430, 643, 174, 149])
words.set('shaming', [430, 643, 174, 149])
words.set('neglect', [536, 614, 182, 124])
words.set('neglecting', [536, 614, 182, 124])
words.set('neglected', [536, 614, 182, 124])
words.set('despair', [563, 793, 131, 174])
words.set('dispaired', [563, 793, 131, 174])
words.set('dispairing', [563, 793, 131, 174])
words.set('dispairingly', [563, 793, 131, 174])
words.set('stun', [714, 732, 21, -1])
words.set('stunned', [714, 732, 21, -1])
words.set('stunning', [714, 732, 21, -1])
words.set('confuse', [420, 590, 152, 126])
words.set('confused', [420, 590, 152, 126])
words.set('confusingly', [420, 590, 152, 126])
words.set('cheerful', [492, 269, 89, 43])
words.set('cheerfully', [492, 269, 89, 43])
words.set('cheered', [492, 269, 89, 43])
words.set('kiss', [618, 249, 45, 31])
words.set('kissing', [618, 249, 45, 31])
words.set('kissed', [618, 249, 45, 31])
words.set('proud', [470, 302, 148, 67])
words.set('proudly', [470, 302, 148, 67])
words.set('pride', [470, 302, 148, 67])
words.set('optimist', [360, 278, 103, 40])
words.set('optimistic', [360, 278, 103, 40])
words.set('optimistically', [360, 278, 103, 40])
words.set('pessemist', [766, 461, 162, 162])
words.set('pessemistic', [766, 461, 162, 162])
words.set('pessemistically', [766, 461, 162, 162])
words.set('enthusiast', [336, 288, 51, 39])
words.set('enthusiastic', [336, 288, 51, 39])
words.set('enthusiastic', [336, 288, 51, 39])
words.set('enthused', [336, 288, 51, 39])
words.set('enthusiasm', [336, 288, 51, 39])
words.set('elated', [468, 232, 41, 41])
words.set('elation', [468, 232, 41, 41])
words.set('enthralled', [678, 644, 55, 59])
words.set('enthralling', [678, 644, 55, 59])
words.set('affection', [641, 260, 67, 39])
words.set('affectionate', [641, 260, 67, 39])
words.set('affectionately', [641, 260, 67, 39])
words.set('longing', [528, 729, 169, 145])
words.set('desire', [323, 321, 21, 88])
words.set('desired', [323, 321, 21, 88])
words.set('desiring', [323, 321, 21, 88])
words.set('peace', [516, 232, 178, 41])
words.set('peaceful', [516, 232, 178, 41])
words.set('peacefully', [516, 232, 178, 41])
words.set('scared', [764, 473, 31, 163])
words.set('scary', [764, 473, 31, 163])
words.set('scaringly', [764, 473, 31, 163])
words.set('scare', [764, 473, 31, 163])
words.set('insecure', [650, 484, 172, 135])
words.set('insecurity', [650, 484, 172, 135])
words.set('nerves', [729, 478, 19, 137])
words.set('nervous', [729, 478, 19, 137])
words.set('nervously', [729, 478, 19, 137])
words.set('irritable', [257, 504, 28, 163])
words.set('irritation', [257, 504, 28, 163])
words.set('iritably', [257, 504, 28, 163])
words.set('absorbing', [270, 310, 55, 29])
words.set('adventure', [338, 291, 48, 41])
words.set('adventuring', [338, 291, 48, 41])
words.set('adventurous', [338, 291, 48, 41])
words.set('alert', [295, 286, 33, 52])
words.set('alerted', [295, 286, 33, 52])
words.set('alerting', [295, 286, 33, 52])
words.set('warn', [751, 506, 60, 173])
words.set('warned', [751, 506, 60, 173])
words.set('warning', [751, 506, 60, 173])
words.set('agree', [671, 327, 87, 61])
words.set('agreable', [671, 327, 87, 61])
words.set('agreeing', [671, 327, 87, 61])
words.set('agreed', [671, 327, 87, 61])
words.set('alluring', [353, 275, 63, 70])
words.set('allure', [353, 275, 63, 70])
words.set('allured', [353, 275, 63, 70])
words.set('applaud', [665, 295, 43, 51])
words.set('applauding', [665, 295, 43, 51])
words.set('applause', [665, 295, 43, 51])
words.set('appreciate', [680, 308, 121, 52])
words.set('appreciated', [680, 308, 121, 52])
words.set('appreciating', [680, 308, 121, 52])
words.set('astound', [694, 705, 27, 96])
words.set('astounding', [694, 705, 27, 96])
words.set('astounded', [694, 705, 27, 96])
words.set('attentive', [370, 371, 29, 62])
words.set('attentively', [370, 371, 29, 62])
words.set('awake', [655, 629, 25, 72])
words.set('awoken', [655, 629, 25, 72])
words.set('awakened', [655, 629, 25, 72])
words.set('startle', [716, 684, 18, 134])
words.set('startled', [716, 684, 18, 134])
words.set('startline', [716, 684, 18, 134])
words.set('aware', [407, 340, 54, 66])
words.set('awareness', [407, 340, 54, 66])
words.set('beautiful', [512, 261, 55, 40])
words.set('beautifully', [512, 261, 55, 40])
words.set('gorgeous', [512, 261, 55, 40])
words.set('best', [602, 590, 123, 75])
words.set('finest', [602, 590, 123, 75])
words.set('worse', [424, 577, 128, 131])
words.set('worsen', [424, 577, 128, 131])
words.set('greatest', [640, 280, 94, 69])
words.set('nice', [470, 270, 139, 71])
words.set('nicest', [602, 355, 139, 71])
words.set('bless', [545, 266, 150, 44])
words.set('blessed', [545, 266, 150, 44])
words.set('blessing', [545, 266, 150, 44])
words.set('calm', [446, 270, 187, 52])
words.set('calming', [446, 270, 187, 52])
words.set('calmest', [446, 270, 187, 52])
words.set('capable', [704, 303, 56, 40])
words.set('captivating', [275, 272, 14, 38])
words.set('charming', [671, 295, 70, 70])
words.set('charmed', [671, 295, 70, 70])
words.set('charm', [671, 295, 70, 70])
words.set('charisma', [671, 295, 70, 70])
words.set('charismatic', [671, 295, 70, 70])
words.set('clever', [605, 355, 82, 50])
words.set('cleverest', [605, 355, 82, 50])
words.set('commended', [744, 344, 38, 51])
words.set('commended', [744, 344, 38, 51])
words.set('commendable', [744, 344, 38, 51])
words.set('competent', [674, 374, 81, 63])
words.set('competently', [674, 374, 81, 63])
words.set('compliment', [613, 278, 92, 56])
words.set('complimented', [613, 278, 92, 56])
words.set('complimenting', [613, 278, 92, 56])
words.set('conscious', [605, 571, 86, 91])
words.set('consciously', [605, 571, 86, 91])
words.set('cute', [605, 368, 107, 65])
words.set('cutest', [605, 368, 107, 65])
words.set('dazzle', [673, 713, 28, 49])
words.set('dazzled', [673, 713, 28, 49])
words.set('dazzling', [673, 713, 28, 49])
words.set('delightful', [679, 630, 40, 49])
words.set('delight', [643, 634, 52, 53])
words.set('delighted', [643, 634, 52, 53])
words.set('dliigent', [378, 291, 41, 72])
words.set('diligently', [378, 291, 41, 72])
words.set('eager', [374, 301, 38, 72])
words.set('eagerly', [374, 301, 38, 72])
words.set('fair', [626, 310, 76, 68])
words.set('fairest', [626, 310, 76, 68])
words.set('friend', [603, 310, 92, 54])
words.set('friendly', [603, 310, 92, 54])
words.set('friendly', [603, 310, 92, 54])
words.set('Imaginative', [437, 328, 67, 67])
words.set('Imagination', [437, 328, 67, 67])
words.set('Imagining', [437, 328, 67, 67])
words.set('Imagination', [437, 328, 67, 67])
words.set('Imaginatively', [437, 328, 67, 67])
words.set('Intuit', [371, 392, 84, 69])
words.set('Intuitive', [371, 392, 84, 69])
words.set('keen', [348, 304, 37, 67])
words.set('keeness', [348, 304, 37, 67])
//words.set('know', [433, 370, 55, 86])
//words.set('knowing', [433, 370, 55, 86])
//words.set('knew', [433, 370, 55, 86])
words.set('Motivate', [650, 607, 49, 66])
words.set('Motivating', [650, 607, 49, 66])
words.set('Motivated', [650, 607, 49, 66])
words.set('Relax', [552, 232, 190, 43])
words.set('Relaxing', [552, 232, 190, 43])
words.set('Relaxed', [552, 232, 190, 43])
words.set('Safe', [591, 304, 166, 57])
words.set('Safer', [591, 304, 166, 57])
words.set('Safety', [591, 304, 166, 57])
words.set('Safely', [591, 304, 166, 57])
words.set('Safest', [591, 304, 166, 57])
words.set('Secure', [604, 327, 128, 65])
words.set('Secured', [604, 327, 128, 65])
words.set('Securely', [604, 327, 128, 65])
words.set('Security', [604, 327, 128, 65])
words.set('Skillful', [580, 337, 59, 79])
words.set('Skillfully', [580, 337, 59, 79])
words.set('Skill', [580, 337, 59, 79])
words.set('Skills', [580, 337, 59, 79])
words.set('Skilled', [580, 337, 59, 79])
words.set('Smashing', [234, 481, 19, 172])
words.set('Smash', [234, 481, 19, 172])
words.set('Smashed', [234, 481, 19, 172])
words.set('Sparkling', [671, 632, 34, 60])
words.set('Sparked', [671, 632, 34, 60])
words.set('Spark', [671, 632, 34, 60])
words.set('Swift', [635, 595, 36, 78])
words.set('Swiftly', [635, 595, 36, 78])
words.set('Success', [629, 584, 54, 67])
words.set('Successful', [629, 584, 54, 67])
words.set('Successfully', [629, 584, 54, 67])
words.set('Tantalize', [324, 302, 32, 75])
words.set('Tantalizing', [324, 302, 32, 75])
words.set('Teeming', [654, 609, 48, 68])
words.set('Tranquil', [610, 350, 187, 64])
words.set('Tranquility', [610, 350, 187, 64])
words.set('Vivid', [713, 641, 35, 83])
words.set('Vividly', [713, 641, 35, 83])
words.set('Zeal', [714, 649, 21, 65])
words.set('Zest', [714, 649, 21, 65])
words.set('Zestful', [714, 649, 21, 65])
words.set('Zestfully', [714, 649, 21, 65])
words.set('rough', [402, 587, 66, 127])
words.set('roughly', [402, 587, 66, 127])
words.set('raw', [402, 587, 66, 127])
words.set('gentle', [535, 361, 155, 76])
words.set('gently', [535, 361, 155, 76])
words.set('gentlest', [535, 361, 155, 76])
words.set('rude', [279, 557, 54, 154])
words.set('rudely', [279, 557, 54, 154])
words.set('rudest', [279, 557, 54, 154])
words.set('polite', [617, 337, 75, 63])
words.set('politely', [617, 337, 75, 63])
words.set('politest', [617, 337, 75, 63])
words.set('dangerous', [751, 512, 26, 155])
words.set('danger', [751, 512, 26, 155])
words.set('dangerously', [751, 512, 26, 155])
words.set('endangered', [751, 512, 26, 155])
words.set('regret', [515, 730, 157, 161])
words.set('regretfully', [515, 730, 157, 161])
words.set('regretful', [515, 730, 157, 161])
words.set('regretting', [515, 730, 157, 161])
words.set('regretedly', [515, 730, 157, 161])
words.set('satisfy', [378, 370, 86, 51])
words.set('satisfaction', [378, 370, 86, 51])
words.set('satisfactory', [378, 370, 86, 51])
words.set('satisfied', [378, 370, 86, 51])
words.set('scream', [815, 455, 15, 168])
words.set('screaming', [815, 455, 15, 168])
words.set('screamed', [815, 455, 15, 168])
words.set('whisper', [346, 360, 134, 65])
words.set('whispered', [346, 360, 134, 65])
words.set('seldom', [306, 275, 0, 113])
words.set('serious', [291, 325, 53, 68])
words.set('seriously', [291, 325, 53, 68])
words.set('funny', [665, 685, 29, 39])
words.set('funnily', [665, 685, 29, 39])
words.set('funniest', [665, 685, 29, 39])
words.set('noisy', [324, 573, 34, 147])
words.set('noise', [324, 573, 34, 147])
words.set('noisily', [324, 573, 34, 147])
words.set('noisiest', [324, 573, 34, 147])
words.set('complicate', [626, 583, 44, 131])
words.set('complicated', [626, 583, 44, 131])
words.set('complicatedly', [626, 583, 44, 131])
words.set('married', [608, 265, 51, 50])
words.set('marry', [608, 265, 51, 50])
words.set('clever', [711, 309, 79, 50])
words.set('cleverly', [711, 309, 79, 50])
words.set('cleverest', [711, 309, 79, 50])
words.set('stupid', [320, 680, 111, 137])
words.set('stupidly', [340, 660, 111, 137])
words.set('stupidest', [355, 640, 111, 137])
words.set('dumb', [410, 592, 111, 137])
words.set('dumbest', [410, 592, 111, 137])
words.set('fail', [692, 518, 153, 144])
words.set('failed', [692, 518, 153, 144])
words.set('failing', [692, 518, 153, 144])
words.set('failure', [692, 518, 153, 144])
words.set('fake', [387, 609, 47, 151])
words.set('faking', [387, 609, 47, 151])
words.set('defeat', [287, 491, 38, 110])
words.set('defeated', [287, 491, 38, 110])
words.set('defeating', [287, 491, 38, 110])
words.set('war', [226, 481, 36, 141])
words.set('waring', [226, 481, 36, 141])
words.set('damage', [242, 506, 52, 150])
words.set('damaged', [242, 506, 52, 150])
words.set('damaging', [242, 506, 52, 150])
words.set('apathetic', [426, 587, 193, 133])
words.set('apathetically', [426, 587, 193, 133])
words.set('apathy', [426, 587, 193, 133])
words.set('impatient', [368, 554, 103, 131])
words.set('impatiently', [368, 554, 103, 131])
words.set('patient', [359, 295, 182, 40])
words.set('patiently', [359, 295, 182, 40])
words.set('betray', [406, 716, 103, 153])
words.set('betrayed', [406, 716, 103, 153])
words.set('betraying', [406, 716, 103, 153])
words.set('dissapoint', [610, 678, 142, 138])
words.set('dissapointed', [610, 678, 142, 138])
words.set('dissapointingly', [610, 678, 142, 138])
words.set('embarrassed', [374, 655, 102, 150])
words.set('embarrassing', [374, 655, 102, 150])
words.set('embarrassment', [374, 655, 102, 150])
words.set('embarrassingly', [374, 655, 102, 150])
words.set('jealous', [299, 622, 78, 150])
words.set('jealously', [299, 622, 78, 150])
words.set('abysmal', [273, 690, 113, 174])
words.set('enraged', [180, 541, 8, 190])
words.set('enraging', [180, 541, 8, 190])
words.set('enrage', [180, 541, 8, 190])
words.set('hurt', [522, 766, 0, 163])
words.set('hurting', [522, 766, 0, 163])
words.set('hurtful', [522, 766, 0, 163])
words.set('hurtfully', [522, 766, 0, 163])
words.set('malice', [222, 601, 33, 184])
words.set('malicious', [222, 601, 33, 184])
words.set('maliciously', [222, 601, 33, 184])
words.set('pain', [504, 764, 34, 160])
words.set('painful', [504, 764, 34, 160])
words.set('painfully', [504, 764, 34, 160])
words.set('reject', [373, 593, 132, 158])
words.set('rejecting', [373, 593, 132, 158])
words.set('sinister', [327, 594, 92, 154])
words.set('tense', [723, 525, 22, 149])
words.set('tensly', [723, 525, 22, 149])
words.set('ugly', [353, 701, 144, 143])
words.set('ugliest', [353, 701, 144, 143])
words.set('yell', [228, 514, 35, 156])
words.set('yelling', [228, 514, 35, 156])
words.set('yelled', [228, 514, 35, 156])
words.set('dread', [745, 470, 147, 142])
words.set('dreading', [745, 470, 147, 142])
words.set('dreadful', [392, 676, 149, 153])
words.set('dreadfully', [392, 676, 149, 153])
words.set('nasty', [243, 456, 81, 154])
words.set('nastiness', [243, 456, 81, 154])
words.set('nastiest', [243, 456, 81, 154])
words.set('ruthless', [369, 472, 43, 118])
words.set('ruthlessness', [369, 472, 43, 118])
words.set('savage', [199, 439, 24, 160])
words.set('savagely', [199, 439, 24, 160])
words.set('vicious', [176, 511, 38, 188])
words.set('viciously', [176, 511, 38, 188])
words.set('viciousness', [176, 511, 38, 188])
words.set('weary', [530, 765, 177, 167])
words.set('weariness', [530, 765, 177, 167])
words.set('yuck', [332, 713, 68, 158])
words.set('yucky', [332, 713, 68, 158])
words.set('alarming', [748, 570, 17, 139])
words.set('alarm', [748, 570, 17, 139])
words.set('alarmed', [748, 570, 17, 139])
words.set('alarmingly', [748, 570, 17, 139])
words.set('dirty', [340, 705, 137, 138])
words.set('dirt', [340, 705, 137, 138])
words.set('unclean', [340, 705, 137, 138])
words.set('uncleaned', [340, 705, 137, 138])
words.set('injure', [349, 511, 28, 149])
words.set('injured', [349, 511, 28, 149])
words.set('injury', [349, 511, 28, 149])
words.set('dead', [470, 740, 169, 127])
words.set('died', [518, 710, 169, 127])
words.set('death', [493, 7059, 169, 127])
words.set('deathly', [453, 639, 169, 127])
words.set('deceased', [453, 639, 169, 127])
words.set('disease', [437, 685, 154, 135])
words.set('diseased', [437, 685, 154, 135])
words.set('ill', [499, 713, 157, 132])
words.set('unwell', [499, 713, 157, 132])
words.set('illness', [499, 713, 157, 132])
words.set('theat', [274, 496, 37, 132])
words.set('threatening', [274, 496, 37, 132])
words.set('threaten', [274, 496, 37, 132])
words.set('suspicious', [749, 465, 54, 154])
words.set('suspiciously', [749, 465, 54, 154])
words.set('suspicion', [749, 465, 54, 154])
words.set('fight', [240, 477, 13, 161])
words.set('fighting', [240, 477, 13, 161])
words.set('fought', [240, 477, 13, 161])
words.set('poison', [242, 596, 58, 177])
words.set('poisonous', [242, 596, 58, 177])
words.set('poisoning', [242, 596, 58, 177])
words.set('poisoned', [242, 596, 58, 177])
words.set('repluse', [325, 694, 80, 141])
words.set('repulsive', [325, 694, 80, 141])
words.set('repulsed', [325, 694, 80, 141])
words.set('spite', [228, 563, 32, 161])
words.set('spiteful', [228, 563, 32, 161])
words.set('spitefully', [228, 563, 32, 161])
words.set('cry', [477, 797, 184, 170])
words.set('crying', [477, 797, 184, 170])
words.set('cried', [477, 797, 184, 170])
words.set('tearful', [477, 797, 184, 170])
words.set('tearfully', [477, 797, 184, 170])
words.set('tore', [314, 476, 20, 146])
words.set('tearing', [314, 476, 20, 146])
words.set('tare', [314, 476, 20, 146])
words.set('lie', [355, 630, 44, 147])
words.set('lied', [359, 580, 44, 147])
words.set('liar', [345, 600, 44, 147])
words.set('lieing', [355, 570, 44, 147])
words.set('fright', [751, 476, 15, 147])
words.set('frighten', [751, 476, 15, 147])
words.set('frightened', [751, 476, 15, 147])
words.set('fightened', [751, 476, 15, 147])
words.set('grotesque', [291, 742, 11, 158])
words.set('grotesquely', [291, 742, 11, 158])
words.set('grusome', [788, 471, 59, 173])
words.set('horrible', [327, 653, 81, 171])
words.set('horrid', [327, 653, 81, 171])
words.set('horribly', [327, 653, 81, 171])
words.set('awful', [431, 707, 129, 165])
words.set('awfully', [431, 707, 129, 165])
words.set('distress', [668, 465, 20, 161])
words.set('distressed', [668, 465, 20, 161])
words.set('distressing', [668, 465, 20, 161])
words.set('hostile', [226, 494, 18, 161])
words.set('hostility', [226, 494, 18, 161])
words.set('monster', [751, 443, 28, 135])
words.set('monstrous', [751, 443, 28, 135])




words.set('FEAR', [ 767,491, 0,0 ])
words.set('alarm', [ 767,555, 0,0 ])
words.set('anxiety', [ 788,448, 0,0 ])
words.set('dread', [ 799,441, 0,0 ])
words.set('fearfulness', [ 760,494, 0,0 ])
words.set('fright', [ 783,594, 0,0 ])
words.set('horror', [ 814,520, 0,0 ])
words.set('panic', [ 762,454, 0,0 ])
words.set('scare', [ 710,582, 0,0 ])
words.set('terror', [ 791,559, 0,0 ])
words.set('trepidation', [ 677,466, 0,0 ])
words.set('agita', [ 695,489, 0,0 ])
words.set('agitation', [ 262,421, 0,0 ])
words.set('anxiety', [ 761,511, 0,0 ])
words.set('anxiousness', [ 758,416, 0,0 ])
words.set('apprehension', [ 683,572, 0,0 ])
words.set('apprehensiveness', [ 674,556, 0,0 ])
words.set('care', [ 595,258, 0,0 ])
words.set('concern', [ 572,301, 0,0 ])
words.set('concernment', [ 555,299, 0,0 ])
words.set('disquiet', [ 666,622, 0,0 ])
words.set('disquietude', [ 662,638, 0,0 ])
words.set('nervosity', [ 726,419, 0,0 ])
words.set('nervousness', [ 736,407, 0,0 ])
words.set('perturbation', [ 697,451, 0,0 ])
words.set('solicitude', [ 708,379, 0,0 ])
words.set('sweat', [ 731,531, 0,0 ])
words.set('unease', [ 747,417, 0,0 ])
words.set('uneasiness', [ 738,411, 0,0 ])
words.set('worry', [ 305,340, 0,0 ])
words.set('strain', [ 292,391, 0,0 ])
words.set('stress', [ 279,360, 0,0 ])
words.set('tension', [ 282,342, 0,0 ])
words.set('alarm (also alarum)', [ 738,637, 0,0 ])
words.set('anguish', [ 697,564, 0,0 ])
words.set('consternation', [ 705,533, 0,0 ])
words.set('desperateness', [ 711,416, 0,0 ])
words.set('desperation', [ 719,408, 0,0 ])
words.set('discomfort', [ 416,645, 0,0 ])
words.set('discomposure', [ 693,427, 0,0 ])
words.set('dismay', [ 285,641, 0,0 ])
words.set('distraction', [ 354,278, 0,0 ])
words.set('distress', [ 590,718, 0,0 ])
words.set('disturbance', [ 616,611, 0,0 ])
words.set('edginess', [ 637,549, 0,0 ])
words.set('franticness', [ 673,550, 0,0 ])
words.set('hand-wringing', [ 656,458, 0,0 ])
words.set('jitters', [ 657,578, 0,0 ])
words.set('jumpiness', [ 759,624, 0,0 ])
words.set('panic', [ 786,561, 0,0 ])
words.set('tremor', [ 785,533, 0,0 ])
words.set('angst', [ 739,414, 0,0 ])
words.set('fearfulness', [ 797,459, 0,0 ])
words.set('torment', [ 728,454, 0,0 ])
words.set('upset', [ 455,769, 0,0 ])
words.set('vexation', [ 348,673, 0,0 ])
words.set('cold feet', [ 670,458, 0,0 ])
words.set('doubt', [ 605,370, 0,0 ])
words.set('dread', [ 782,447, 0,0 ])
words.set('foreboding', [ 644,462, 0,0 ])
words.set('incertitude', [ 294,336, 0,0 ])
words.set('misgiving', [ 697,369, 0,0 ])
words.set('presentiment', [ 664,476, 0,0 ])
words.set('suspense', [ 651,538, 0,0 ])
words.set('uncertainty', [ 308,315, 0,0 ])
words.set('compunction', [ 330,302, 0,0 ])
words.set('qualm', [ 686,489, 0,0 ])
words.set('scruple', [ 708,383, 0,0 ])
words.set('phobia', [ 795,509, 0,0 ])
words.set('creeps', [ 795,465, 0,0 ])
words.set('jitters', [ 734,515, 0,0 ])
words.set('nervousness', [ 679,588, 0,0 ])
words.set('willies', [ 701,592, 0,0 ])
words.set('pang', [ 656,510, 0,0 ])
words.set('qualm', [ 348,373, 0,0 ])
words.set('twinge', [ 658,572, 0,0 ])
words.set('agitation', [ 236,435, 0,0 ])
words.set('apprehension', [ 288,342, 0,0 ])
words.set('consternation', [ 396,249, 0,0 ])
words.set('discomposure', [ 660,425, 0,0 ])
words.set('disquiet', [ 658,631, 0,0 ])
words.set('funk', [ 634,541, 0,0 ])
words.set('perturbation', [ 638,600, 0,0 ])
words.set('concern', [ 662,403, 0,0 ])
words.set('dismay', [ 434,673, 0,0 ])
words.set('worry', [ 667,512, 0,0 ])
words.set('cowardice', [ 709,465, 0,0 ])
words.set('faintheartedness', [ 672,534, 0,0 ])
words.set('timidity', [ 725,444, 0,0 ])
words.set('timorousness', [ 735,463, 0,0 ])
words.set('bother', [ 691,636, 0,0 ])
words.set('fret', [ 710,656, 0,0 ])
words.set('fuss', [ 354,316, 0,0 ])
words.set('stew', [ 699,627, 0,0 ])
words.set('stress', [ 350,282, 0,0 ])
words.set('sweat', [ 326,356, 0,0 ])
words.set('trouble', [ 704,509, 0,0 ])
words.set('worry', [ 770,484, 0,0 ])
words.set('agonize', [ 796,423, 0,0 ])
words.set('pine', [ 547,762, 0,0 ])
words.set('yearn', [ 486,780, 0,0 ])
words.set('chafe', [ 521,767, 0,0 ])
words.set('despair', [ 413,722, 0,0 ])
words.set('chill', [ 735,557, 0,0 ])
words.set('daunt', [ 661,444, 0,0 ])
words.set('demoralize', [ 739,317, 0,0 ])
words.set('dispirit', [ 730,327, 0,0 ])
words.set('emasculate', [ 545,751, 0,0 ])
words.set('psych (out)', [ 740,399, 0,0 ])
words.set('undo', [ 628,713, 0,0 ])
words.set('unman', [ 644,715, 0,0 ])
words.set('unnerve', [ 778,477, 0,0 ])
words.set('unstring', [ 697,366, 0,0 ])
words.set('AWE', [ 790,626, 0,0 ])
words.set('admiration', [ 661,241, 0,0 ])
words.set('amazement', [ 330,272, 0,0 ])
words.set('astonishment', [ 715,693, 0,0 ])
words.set('wonder', [ 355,247, 0,0 ])
words.set('wonderment', [ 365,243, 0,0 ])
words.set('discomfort', [ 518,631, 0,0 ])
words.set('discompose', [ 553,617, 0,0 ])
words.set('disconcert', [ 593,629, 0,0 ])
words.set('disquiet', [ 365,319, 0,0 ])
words.set('distract', [ 314,318, 0,0 ])
words.set('distress', [ 665,476, 0,0 ])
words.set('disturb', [ 668,681, 0,0 ])
words.set('perturb', [ 691,679, 0,0 ])
words.set('unsettle', [ 678,726, 0,0 ])
words.set('upset', [ 593,773, 0,0 ])
words.set('worry', [ 788,467, 0,0 ])
words.set('appall', [ 328,710, 0,0 ])
words.set('bowl over', [ 692,693, 0,0 ])
words.set('dismay', [ 338,706, 0,0 ])
words.set('floor', [ 729,697, 0,0 ])
words.set('jolt', [ 743,680, 0,0 ])
words.set('shake', [ 785,539, 0,0 ])
words.set('shake up', [ 789,516, 0,0 ])
words.set('SURPRISE', [ 728,713, 0,0 ])
words.set('shock', [ 773,627, 0,0 ])
words.set('thunderclap', [ 700,639, 0,0 ])
words.set('eye-opener', [ 680,671, 0,0 ])
words.set('revelation', [ 681,696, 0,0 ])
words.set('shocker', [ 722,701, 0,0 ])
words.set('amazement', [ 743,673, 0,0 ])
words.set('marvel', [ 642,634, 0,0 ])
words.set('wonder', [ 703,657, 0,0 ])
words.set('fillip', [ 695,671, 0,0 ])
words.set('kick', [ 263,390, 0,0 ])
words.set('kicker', [ 257,403, 0,0 ])
words.set('twist', [ 664,652, 0,0 ])
words.set('wrinkle', [ 665,665, 0,0 ])
words.set('amazement', [ 729,683, 0,0 ])
words.set('astonishment', [ 716,713, 0,0 ])
words.set('shock', [ 772,645, 0,0 ])
words.set('startlement', [ 761,634, 0,0 ])
words.set('stupefaction', [ 745,634, 0,0 ])
words.set('ambuscade', [ 730,637, 0,0 ])
words.set('ambush', [ 779,642, 0,0 ])
words.set('ambushment', [ 778,650, 0,0 ])
words.set('trap', [ 780,594, 0,0 ])
words.set('assault', [ 751,680, 0,0 ])
words.set('attack', [ 226,399, 0,0 ])
words.set('charge', [ 233,411, 0,0 ])
words.set('sally', [ 238,413, 0,0 ])
words.set('capture', [ 753,671, 0,0 ])
words.set('entrapment', [ 768,653, 0,0 ])
words.set('mousetrap', [ 755,648, 0,0 ])
words.set('snare', [ 733,697, 0,0 ])
words.set('hunting', [ 342,264, 0,0 ])
words.set('stalking', [ 791,429, 0,0 ])
words.set('astonish', [ 736,686, 0,0 ])
words.set('amaze', [ 727,688, 0,0 ])
words.set('nonplus', [ 335,270, 0,0 ])
words.set('startle', [ 684,690, 0,0 ])
words.set('astound', [ 681,647, 0,0 ])
words.set('stun', [ 693,614, 0,0 ])
words.set('flabbergast', [ 740,655, 0,0 ])
words.set('stagger', [ 725,668, 0,0 ])
words.set('shock', [ 782,644, 0,0 ])
words.set('stop someone in their tracks', [ 698,737, 0,0 ])
words.set('stupefy', [ 664,673, 0,0 ])
words.set('leave open-mouthed', [ 748,680, 0,0 ])
words.set('breath away', [ 659,619, 0,0 ])
words.set('dumbfound', [ 736,623, 0,0 ])
words.set('daze', [ 740,683, 0,0 ])
words.set('benumb', [ 657,641, 0,0 ])
words.set('confound', [ 656,659, 0,0 ])
words.set('take aback', [ 696,658, 0,0 ])
words.set('jolt', [ 673,710, 0,0 ])
words.set('shake up', [ 717,701, 0,0 ])
words.set('bowl over', [ 679,623, 0,0 ])
words.set('knock for six', [ 700,651, 0,0 ])
words.set('floor', [ 650,624, 0,0 ])
words.set('blow someones mind', [ 735,663, 0,0 ])
words.set('strike dumb', [ 721,677, 0,0 ])
words.set('astonished', [ 681,687, 0,0 ])
words.set('amazed', [ 703,640, 0,0 ])
words.set('in amazement', [ 719,634, 0,0 ])
words.set('nonplussed', [ 337,312, 0,0 ])
words.set('taken aback', [ 627,606, 0,0 ])
words.set('startled', [ 681,623, 0,0 ])
words.set('astounded', [ 747,646, 0,0 ])
words.set('stunned', [ 706,620, 0,0 ])
words.set('flabbergasted', [ 710,649, 0,0 ])
words.set('staggered', [ 626,612, 0,0 ])
words.set('shocked', [ 770,641, 0,0 ])
words.set('shell-shocked', [ 765,595, 0,0 ])
words.set('stupefied', [ 356,332, 0,0 ])
words.set('open-mouthed', [ 665,640, 0,0 ])
words.set('dumbfounded', [ 653,688, 0,0 ])
words.set('dumbstruck', [ 678,669, 0,0 ])
words.set('speechless', [ 712,675, 0,0 ])
words.set('at a loss for words', [ 685,651, 0,0 ])
words.set('thunderstruck', [ 685,629, 0,0 ])
words.set('dazed', [ 678,693, 0,0 ])
words.set('benumbed', [ 697,675, 0,0 ])
words.set('confounded', [ 649,663, 0,0 ])
words.set('agape', [ 663,648, 0,0 ])
words.set('goggle-eyed', [ 696,648, 0,0 ])
words.set('wide-eyed', [ 333,283, 0,0 ])
words.set('jolted', [ 654,661, 0,0 ])
words.set('shaken up', [ 697,668, 0,0 ])
words.set('bowled over', [ 683,697, 0,0 ])
words.set('knocked for six', [ 705,646, 0,0 ])
words.set('floored', [ 657,736, 0,0 ])
words.set('flummoxed', [ 669,723, 0,0 ])
words.set('caught on the hop', [ 721,685, 0,0 ])
words.set('caught on the wrong foot', [ 711,684, 0,0 ])
words.set('unexpected', [ 738,682, 0,0 ])
words.set('unanticipated', [ 669,668, 0,0 ])
words.set('unforeseen', [ 358,307, 0,0 ])
words.set('unpredictable', [ 321,313, 0,0 ])
words.set('unpredicted', [ 334,291, 0,0 ])
words.set('astonishing', [ 779,638, 0,0 ])
words.set('amazing', [ 456,255, 0,0 ])
words.set('startling', [ 670,636, 0,0 ])
words.set('astounding', [ 674,706, 0,0 ])
words.set('striking', [ 346,405, 0,0 ])
words.set('staggering', [ 675,633, 0,0 ])
words.set('incredible', [ 683,677, 0,0 ])
words.set('extraordinary', [ 710,655, 0,0 ])
words.set('dazzling', [ 645,664, 0,0 ])
words.set('breathtaking', [ 676,680, 0,0 ])
words.set('remarkable', [ 377,295, 0,0 ])
words.set('wonderful', [ 455,208, 0,0 ])
words.set('unusual', [ 355,297, 0,0 ])
words.set('mind-blowing', [ 660,675, 0,0 ])
words.set('amazeballs', [ 711,659, 0,0 ])
words.set('backasswards', [ 670,676, 0,0 ])
words.set('DISAPPROVAL', [ 259,627, 0,0 ])
words.set('deprecation', [ 301,689, 0,0 ])
words.set('disapprobation', [ 334,623, 0,0 ])
words.set('discountenance', [ 347,655, 0,0 ])
words.set('disesteem', [ 360,677, 0,0 ])
words.set('disfavor', [ 339,705, 0,0 ])
words.set('dislike', [ 291,697, 0,0 ])
words.set('displeasure', [ 283,664, 0,0 ])
words.set('disrelish', [ 324,701, 0,0 ])
words.set('distaste', [ 360,713, 0,0 ])
words.set('rejection', [ 348,726, 0,0 ])
words.set('thumbs-down', [ 359,671, 0,0 ])
words.set('blame', [ 245,613, 0,0 ])
words.set('censure', [ 337,618, 0,0 ])
words.set('condemnation', [ 303,642, 0,0 ])
words.set('criticism', [ 284,632, 0,0 ])
words.set('denunciation', [ 348,615, 0,0 ])
words.set('dispraise', [ 357,639, 0,0 ])
words.set('opprobrium', [ 380,615, 0,0 ])
words.set('reprehension', [ 373,652, 0,0 ])
words.set('reproach', [ 366,665, 0,0 ])
words.set('reprobation', [ 341,698, 0,0 ])
words.set('antagonism', [ 244,423, 0,0 ])
words.set('antipathy', [ 278,654, 0,0 ])
words.set('hostility', [ 235,403, 0,0 ])
words.set('belittlement', [ 249,614, 0,0 ])
words.set('disparagement', [ 293,598, 0,0 ])
words.set('objection', [ 319,654, 0,0 ])
words.set('opposition', [ 276,624, 0,0 ])
words.set('ANTICIPATION', [ 311,281, 0,0 ])
words.set('contemplation', [ 311,304, 0,0 ])
words.set('expectance', [ 351,284, 0,0 ])
words.set('expectancy', [ 328,283, 0,0 ])
words.set('expectation', [ 363,321, 0,0 ])
words.set('prospect', [ 383,329, 0,0 ])
words.set('assume', [ 360,323, 0,0 ])
words.set('await', [ 336,256, 0,0 ])
words.set('count on', [ 688,296, 0,0 ])
words.set('forecast', [ 311,291, 0,0 ])
words.set('foresee', [ 338,293, 0,0 ])
words.set('prepare for', [ 327,333, 0,0 ])
words.set('conjecture', [ 339,312, 0,0 ])
words.set('divine', [ 565,260, 0,0 ])
words.set('entertain', [ 373,273, 0,0 ])
words.set('figure', [ 363,303, 0,0 ])
words.set('foretell', [ 318,311, 0,0 ])
words.set('prognosticate', [ 333,315, 0,0 ])
words.set('prophesy', [ 302,260, 0,0 ])
words.set('suppose', [ 339,313, 0,0 ])
words.set('visualize', [ 373,285, 0,0 ])
words.set('wait', [ 297,284, 0,0 ])
words.set('bargain for', [ 391,345, 0,0 ])
words.set('be afraid', [ 782,487, 0,0 ])
words.set('count chickens', [ 340,323, 0,0 ])
words.set('cross the bridge', [ 371,321, 0,0 ])
words.set('foretaste', [ 342,292, 0,0 ])
words.set('have a hunch', [ 315,336, 0,0 ])
words.set('hope for', [ 334,278, 0,0 ])
words.set('jump the gun', [ 347,313, 0,0 ])
words.set('look for', [ 346,314, 0,0 ])
words.set('look forward to', [ 297,272, 0,0 ])
words.set('plan on', [ 311,291, 0,0 ])
words.set('OPTIMISM', [ 406,204, 0,0 ])
words.set('bullishness', [ 365,228, 0,0 ])
words.set('sanguinity', [ 415,317, 0,0 ])
words.set('brightness', [ 552,211, 0,0 ])
words.set('cheerfulness', [ 501,199, 0,0 ])
words.set('perkiness', [ 481,195, 0,0 ])
words.set('sunniness', [ 492,208, 0,0 ])
words.set('hope', [ 361,230, 0,0 ])
words.set('hopefulness', [ 348,237, 0,0 ])
words.set('rosiness', [ 419,202, 0,0 ])
words.set('idealism', [ 328,249, 0,0 ])
words.set('meliorism', [ 341,274, 0,0 ])
words.set('anticipation', [ 292,275, 0,0 ])
words.set('certainty', [ 399,260, 0,0 ])
words.set('confidence', [ 391,250, 0,0 ])
words.set('elation', [ 499,188, 0,0 ])
words.set('enthusiasm', [ 454,202, 0,0 ])
words.set('expectation', [ 313,290, 0,0 ])
words.set('happiness', [ 508,188, 0,0 ])
words.set('idealism', [ 394,226, 0,0 ])
words.set('trust', [ 710,265, 0,0 ])
words.set('assurance', [ 726,293, 0,0 ])
words.set('brightness', [ 487,243, 0,0 ])
words.set('buoyancy', [ 431,235, 0,0 ])
words.set('calmness', [ 464,202, 0,0 ])
words.set('cheer', [ 585,209, 0,0 ])
words.set('cheerfulness', [ 568,191, 0,0 ])
words.set('easiness', [ 578,235, 0,0 ])
words.set('encouragement', [ 413,218, 0,0 ])
words.set('exhilaration', [ 524,199, 0,0 ])
words.set('hopefulness', [ 305,263, 0,0 ])
words.set('positivism', [ 362,224, 0,0 ])
words.set('sanguineness', [ 443,325, 0,0 ])
words.set('sureness', [ 357,227, 0,0 ])
words.set('good cheer', [ 409,206, 0,0 ])
words.set('looking on bright side', [ 349,233, 0,0 ])
words.set('rose-colored glasses', [ 359,260, 0,0 ])
words.set('ANGER', [ 203,491, 0,0 ])
words.set('angriness', [ 210,481, 0,0 ])
words.set('birse', [ 360,502, 0,0 ])
words.set('choler', [ 385,489, 0,0 ])
words.set('furor', [ 255,467, 0,0 ])
words.set('fury', [ 200,507, 0,0 ])
words.set('indignation', [ 296,483, 0,0 ])
words.set('irateness', [ 223,405, 0,0 ])
words.set('ire', [ 229,407, 0,0 ])
words.set('lividity', [ 218,433, 0,0 ])
words.set('lividness', [ 204,477, 0,0 ])
words.set('mad', [ 243,434, 0,0 ])
words.set('madness', [ 253,438, 0,0 ])
words.set('mood [archaic]', [ 237,437, 0,0 ])
words.set('outrage', [ 203,552, 0,0 ])
words.set('rage', [ 230,530, 0,0 ])
words.set('spleen', [ 265,500, 0,0 ])
words.set('wrath', [ 256,561, 0,0 ])
words.set('wrathfulness', [ 263,565, 0,0 ])
words.set('aggravation', [ 224,399, 0,0 ])
words.set('annoyance', [ 216,578, 0,0 ])
words.set('exasperation', [ 289,454, 0,0 ])
words.set('irritation', [ 244,422, 0,0 ])
words.set('vexation', [ 264,451, 0,0 ])
words.set('acrimoniousness', [ 238,516, 0,0 ])
words.set('acrimony', [ 248,529, 0,0 ])
words.set('animosity', [ 299,482, 0,0 ])
words.set('antagonism', [ 302,509, 0,0 ])
words.set('antipathy', [ 315,601, 0,0 ])
words.set('bile', [ 333,605, 0,0 ])
words.set('biliousness', [ 324,628, 0,0 ])
words.set('bitterness', [ 263,660, 0,0 ])
words.set('contempt', [ 272,679, 0,0 ])
words.set('embitterment', [ 285,695, 0,0 ])
words.set('empoisonment', [ 294,699, 0,0 ])
words.set('enmity', [ 352,632, 0,0 ])
words.set('grudge', [ 294,661, 0,0 ])
words.set('hostility', [ 227,423, 0,0 ])
words.set('rancor', [ 368,539, 0,0 ])
words.set('envy', [ 280,664, 0,0 ])
words.set('jaundice', [ 336,642, 0,0 ])
words.set('jealousy', [ 228,623, 0,0 ])
words.set('pique', [ 355,603, 0,0 ])
words.set('resentment', [ 330,738, 0,0 ])
words.set('malevolence', [ 217,546, 0,0 ])
words.set('malice', [ 203,512, 0,0 ])
words.set('spite', [ 221,470, 0,0 ])
words.set('vengefulness', [ 266,501, 0,0 ])
words.set('venom', [ 291,480, 0,0 ])
words.set('vindictiveness', [ 303,542, 0,0 ])
words.set('virulence', [ 331,536, 0,0 ])
words.set('vitriol', [ 330,536, 0,0 ])
words.set('belligerence', [ 299,649, 0,0 ])
words.set('contentiousness', [ 323,689, 0,0 ])
words.set('contrariness', [ 320,660, 0,0 ])
words.set('crankiness', [ 238,493, 0,0 ])
words.set('disputatiousness', [ 304,417, 0,0 ])
words.set('hot-headedness', [ 230,446, 0,0 ])
words.set('irascibility', [ 311,499, 0,0 ])
words.set('irascibleness', [ 318,487, 0,0 ])
words.set('irritability', [ 258,533, 0,0 ])
words.set('orneriness', [ 323,505, 0,0 ])
words.set('pugnaciousness', [ 328,476, 0,0 ])
words.set('pugnacity', [ 321,480, 0,0 ])
words.set('quarrelsomeness', [ 225,430, 0,0 ])
words.set('querulousness', [ 233,438, 0,0 ])
words.set('blowup', [ 213,427, 0,0 ])
words.set('flare', [ 223,418, 0,0 ])
words.set('flare-up', [ 300,427, 0,0 ])
words.set('outburst', [ 311,441, 0,0 ])
words.set('chafe', [ 300,459, 0,0 ])
words.set('dander', [ 302,485, 0,0 ])
words.set('dudgeon', [ 293,522, 0,0 ])
words.set('huff', [ 279,438, 0,0 ])
words.set('pet', [ 619,291, 0,0 ])
words.set('rise', [ 552,281, 0,0 ])
words.set('ruffle', [ 687,641, 0,0 ])
words.set('temper', [ 305,430, 0,0 ])
words.set('enrage', [ 240,415, 0,0 ])
words.set('incense', [ 369,611, 0,0 ])
words.set('inflame (also enflame)', [ 278,418, 0,0 ])
words.set('infuriate', [ 221,463, 0,0 ])
words.set('ire', [ 358,476, 0,0 ])
words.set('mad', [ 251,517, 0,0 ])
words.set('madden', [ 249,504, 0,0 ])
words.set('outrage', [ 311,579, 0,0 ])
words.set('rankle', [ 325,550, 0,0 ])
words.set('rile', [ 352,559, 0,0 ])
words.set('roil', [ 358,546, 0,0 ])
words.set('steam up', [ 336,662, 0,0 ])
words.set('tick off', [ 268,634, 0,0 ])
words.set('affront', [ 245,625, 0,0 ])
words.set('aggravate', [ 222,565, 0,0 ])
words.set('annoy', [ 228,552, 0,0 ])
words.set('burn (up)', [ 259,552, 0,0 ])
words.set('cross', [ 249,561, 0,0 ])
words.set('exasperate', [ 252,435, 0,0 ])
words.set('huff', [ 264,551, 0,0 ])
words.set('irritate', [ 260,431, 0,0 ])
words.set('miff', [ 324,461, 0,0 ])
words.set('nettle', [ 328,639, 0,0 ])
words.set('offend', [ 278,648, 0,0 ])
words.set('peeve', [ 331,636, 0,0 ])
words.set('pique', [ 335,648, 0,0 ])
words.set('provoke', [ 262,395, 0,0 ])
words.set('put out', [ 322,449, 0,0 ])
words.set('ruffle', [ 288,427, 0,0 ])
words.set('vex', [ 300,471, 0,0 ])
words.set('antagonize', [ 252,445, 0,0 ])
words.set('embitter', [ 263,487, 0,0 ])
words.set('envenom', [ 243,509, 0,0 ])
words.set('bollocks', [ 202,474, 0,0 ])
words.set('bitch', [ 201,507, 0,0 ])
words.set('feck', [ 218,527, 0,0 ])
words.set('bastard', [218, 562, 0, 0])

const scoredWords: Map<string, number[]> = words
const scoredWordsArray: string[] = Array.from(words.keys())


export {
    scoredWords,
    scoredWordsArray
}
