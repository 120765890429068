import '../css/Circle.css'
import '../css/Header.css'


export const Header = () => {

    return (

        <header className="header">
            <div className="header__website-name">words feel</div>
            <div className="header__logo"></div>
        </header>

    )
}